@import '../../../../style/configs/color.css';

#login {
    height: 100vh;
    background: url("../../../../assets/img/back login.png");
    position: relative;
    .form-control{
        border-radius: 0.25rem;
    }
    .form-group {
        max-height: 62px;
        opacity: 0;
    }
    .invalid-feedback{
        color: #98CADA;
        margin-top: 0;
    }
    label {
        color: white;
        font-weight: bold;
        margin-left: 5px;
    }
    #nodo {
        position: absolute;
    }
    #forma {
        position: absolute;
        left: -90px;
        top: -24px;
    }
    .extend {
        #login-form {
            width: 485px;
        }
    }
    .show {
        .form-group {
            opacity: 1;
            -webkit-transition: opacity 0.7s;
            -moz-transition: opacity 0.7s;
            -ms-transition: opacity 0.7s;
            -o-transition: opacity 0.7s;
            transition: opacity 0.7s;
        }
        .btn {
            opacity: 1;
            -webkit-transition: opacity 0.7s;
            -moz-transition: opacity 0.7s;
            -ms-transition: opacity 0.7s;
            -o-transition: opacity 0.7s;
            transition: opacity 0.7s;
        }
    }
}
#login img{
    max-width: 300px;
}
#login-form {
    background: -webkit-linear-gradient(to right, $azul-principal, $gris-principal);
    background: -o-linear-gradient(to right, $azul-principal, $gris-principal);
    background: linear-gradient(to right, $azul-principal, $gris-principal);
    -webkit-transition: width 0.7s;
    -moz-transition: width 0.7s;
    -ms-transition: width 0.7s;
    -o-transition: width 0.7s;
    transition: width 0.7s;
    padding: 1.5cm 1cm 1cm 2.7cm;
    width: 0;
    border-radius: 140px 15px 15px 15px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    .btn {
        border-radius : 7px;

        opacity: 0;
    }
}

@media only screen and (max-width: 767px) {
    #login {
        #forma {
            display: none;
        }
    }
    #login-form {
        width: unset !important;
        padding: 1cm !important;
        border-radius: 15px !important;
    }
}

@import '../../../../../../style/configs/color.css';

.renglon-0{
    background-color: #EBEBEB;
}
.renglon-0:hover{
    background-color: #CECECE;
}
.renglon-1:hover{
    background-color: #CECECE;
}
#renglones {

    .expanded {
        background: -webkit-linear-gradient(to left, $azul-principal, $gris-principal) !important;
        background: -o-linear-gradient(to left, $azul-principal, $gris-principal) !important;
        background: linear-gradient(to left, $azul-principal, $gris-principal) !important;
        cursor: pointer;
        .azul {
            color: white !important;
        }
        .gris {
            color: white !important;
        }
    }

    .collapsed {
        cursor: pointer;
    }
    .herramienta {
        color: $azul-principal;
    }

}

@import '../../../../../style/configs/color.css';

#menu {
    .menu-item{
        text-transform: uppercase;
        font-weight: bold;
        color: #fff !important;
        padding: .25cm .5cm;
        font-size: 13px;
        text-decoration: none !important;
        cursor: pointer;
        img {
            max-height: 1rem;
        }
        .img-inactivo{
            display: unset;
        }
        .img-activo{
            display: none;
        }
        .img-expanded{
            display: none;
        }
    }
    .menu-item:hover{
        color: #fff !important;
        background-color: $amarillo;
    }
    .menu-item.active{
        color: $amarillo !important;
        background: $azul-principal;
        border-left: solid $amarillo;
        .img-inactivo{
            display: none;
        }
        .img-activo{
            display: unset;
        }
        .img-expanded{
            display: none;
        }
    }
    .menu-item.expanded{
        color: $amarillo !important;
        background: $azul-principal;
        border-left: solid #FFFFFF;
        .img-inactivo{
            display: none;
        }
        .img-activo{
            display: none;
        }
        .img-expanded{
            display: unset;
        }
    }
    .menu-subitem {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff !important;
        background-color: #727981;
        font-family: Ubuntu-Italic, sans-serif;
        padding: .15cm .55cm;
        font-size: 13px;
        text-decoration: none !important;
    }
    .menu-subitem:hover {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff !important;
        background-color: $amarillo;
        font-family: Ubuntu-Italic, sans-serif;
        font-size: 13px;
        text-decoration: none !important;
    }
    .menu-subitem.active {
        color: $amarillo !important;
        background: $azul-principal;
        font-family: Ubuntu-Italic, sans-serif;
        padding: .15cm .55cm;
        text-decoration: none !important;
    }

}

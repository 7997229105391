@import '../../../../../style/configs/color.css';

#sidebar {
    width: 6cm;
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    background: -webkit-linear-gradient(to top, $azul-principal, $gris-principal);
    background: -o-linear-gradient(to top, $azul-principal, $gris-principal);
    background: linear-gradient(to top, $azul-principal, $gris-principal);
}
@media only screen and (max-width: 767px) {
    #sidebar {
        display: none;
    }
}

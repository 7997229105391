/* #date_picker {
    .react-date-picker__wrapper{
        border: 0;
    }
    .react-date-picker__inputGroup__input {
        color: #495057;
    }
    .react-date-picker__inputGroup__day {
        width: 20px !important;
    }
    .react-date-picker__inputGroup__month {
        width: 20px !important;
    }
    .react-date-picker__inputGroup__year {
        width: 43px !important;
    }
    .react-date-picker__inputGroup__leadingZero + .react-date-picker__inputGroup__day {
        width: 10px !important;
    }
    .react-date-picker__inputGroup__leadingZero + .react-date-picker__inputGroup__month {
        width: 10px !important;
    }
}

@media only screen and (max-width: 575px) {
    #date_picker {
        .react-calendar {
            width: 265px !important;
        }
    }
} */
.date-picker-custom {
    border-radius: 1em;
    input {
        border-radius: 1em;
        border: 0.1em solid #cacaca;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        outline-color: transparent !important;
    }
    input:focus {
        border: 0.1em solid #80bdff;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    input:active {
        border: 0.1em solid #80bdff;
    }
}

.date-picker-custom-overlay {
    position: absolute;
    background-color: #FFF;
    z-index: 10000;
    border-radius: 0.5em;
    border: 0.1em solid #cacaca;
}
.DayPicker-Caption > div {
    text-transform: capitalize;
}
.DayPicker-Day {
    padding: 0.1em !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #80bdff !important;
}

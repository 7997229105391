
#Fases{
    .fondo-fases{
        background: -webkit-linear-gradient(to bottom, #EBEBEB, #ACD0DA);
        background: -o-linear-gradient(to bottom, #EBEBEB, #ACD0DA);
        background: linear-gradient(to bottom, #EBEBEB, #ACD0DA);
    }
}
#CrearFase {
    .rc-tabs-tab{
        padding: 0 !important;
    }
}

#DetalleProyecto {
    .rc-tabs-tab{
        padding: 0 !important;
    }
}

#PresupuestoProyecto {
    .rc-tabs-tab{
        padding: 4px 20px !important;
    }
}
.fondo_fase{
    background: -webkit-linear-gradient(to bottom, #EBEBEB, #ACD0DA);
    background: -o-linear-gradient(to bottom, #EBEBEB, #ACD0DA);
    background: linear-gradient(to bottom, #EBEBEB, #ACD0DA);
}
